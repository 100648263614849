import React, { useState } from "react"
import { Form, Formik, Field } from "formik"
import * as Yup from "yup"
import axios from "axios"
import isEnded from "../utils/isEnded"
import Input from "../Components/Input"
import Layout from "../Components/Layout"
import Box from "../Components/Box"
import { RaisedButton } from "../Components/Buttons"
import StartIlliustration from "../assets/images/start-illiustration.jpg"

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Laukas yra privalomas"),
})


export default () => {
  const [isSucces, setIsSuccess] = useState(false)
  const onSubmit = (values, { setFieldError }) => {
    axios
      .post(
        "https://mo.lrt.lt/olimpiada/api/trybegin",
        new URLSearchParams({
          name: values.name,
        }).toString()
      )
      .then(({ data }) => {
        if (data.status === "error") {
          setFieldError("name", data.message)
        } else {

          setIsSuccess(data.message)

          window.location = "https://mo.lrt.lt/try-begin?t=" + data.guid;
        }
      })
      .catch(() => {
        // setIsLoading(false)
      })
  }

  return (
    <Layout>
      <div className="start-page">
        <Box>
          <h1>Treniruotė: MOdisėja 2020 {/*isEnded() ? "Pasitikrink atsakymus" : "Pradėti MOlimpiadą"*/}</h1>

          {!isSucces && (
            <>
              <p>
                Kviečiame tave į treniruotę! Taip vadiname pernykštės MOdisėjos klausimus, kuriuos išbandyti ir tuo pačiu pažinti virtualaus meno nuotykio formatą gali jau dabar.
              </p>

              <p>
                Tereikia įvesti savo vardą – ir pradėki!
              </p>
              {/*isEnded() && (
                <p>
                  Įvesk savo el. pašto adresą, kuriuo registravaisi, ir mes tau
                  atsiųsime asmeninę nuorodą, kur tu galėsi pasitikrinti
                  atsakymus.
                </p>
              )*/}
              {/*!isEnded() && (
                <p>
                  Įvesk savo el. pašto adresą, kuriuo registravaisi, ir mes tau
                  atsiųsime asmeninę dalyvavimo nuorodą.
                </p>
              )*/}

              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  name: "",
                }}
                onSubmit={onSubmit}
                component={() => (
                  <Form>
                    <Field
                      component={Input}
                      type="text"
                      placeholder=" "
                      required
                      name="name"
                      label="Tavo vardas"
                    />

                    <RaisedButton type="submit">Į TRENIRUOTĘ</RaisedButton>
                  </Form>
                )}
              />
            </>
          )}

          {isSucces && <p>{isSucces}</p>}
        </Box>
      </div>

      <div className="tac">
        <img src={StartIlliustration} alt="" />
      </div>
    </Layout>
  )
}
